import { inject } from '@angular/core';
import { isNil } from 'lodash-es';
import {
  combineLatest,
  debounceTime,
  distinctUntilKeyChanged,
  EMPTY,
  filter,
  firstValueFrom,
  Observable,
  switchMap,
  take,
  takeUntil,
  takeWhile,
  tap,
  withLatestFrom,
} from 'rxjs';

import { ANALYTICS_SERVICE, AnalyticsBaseService, PremiumModalCallSource, SessionService, TimeTrackFeature } from '@stsm/analytics';
import { getElapsedTimeInDays } from '@stsm/date/functions/get-elapsed-time-in-days';
import { createSafeDate } from '@stsm/date/functions/safe-date';
import { FeatureTimeTrackingBaseService } from '@stsm/global/composite/services/feature-time-tracking-base.service';
import { FEATURE_TIME_TRACKING_SERVICE } from '@stsm/global/composite/tokens/feature-time-tracking-service.token';
import { TranslationService } from '@stsm/i18n/services/translation.service';
import { OnboardingStoreBase } from '@stsm/onboarding/control/onboarding-store.base';
import { ONBOARDING_STORE } from '@stsm/onboarding/control/onboarding-store.token';
import { GlobalLocalStorageKey } from '@stsm/shared/enums/global-localstorage-key';
import { Tab } from '@stsm/shared/enums/tab';
import { LoggerService } from '@stsm/shared/logger/logger.service';
import { EnvironmentBase } from '@stsm/shared/models/environment-base';
import { RouterStoreFacade } from '@stsm/shared/router-store/router-store-facade.service';
import { BrowserStorageService } from '@stsm/shared/services/browser-storage/browser-storage.service';
import { ENVIRONMENT } from '@stsm/shared/tokens/environment.token';
import { InputPropertiesOf } from '@stsm/shared/types/input-properties-of';
import { PropertiesOf } from '@stsm/shared/types/properties-of';
import { firstValueFromOrNull } from '@stsm/shared/util/rxjs.util';
import { DialogRef } from '@stsm/ui-components/dialogs/models/dialog-ref';
import { DialogService } from '@stsm/ui-components/dialogs/services/dialog.service';
import { PlatformModalService } from '@stsm/ui-components/dialogs/services/platform-modal.service';
import { SimpleDialogService } from '@stsm/ui-components/dialogs/simple-dialog/simple-dialog.service';
import { PaymentProvider } from '@stsm/user/models/payment-provider';
import { PremiumInfo } from '@stsm/user/models/premium-info';
import { User } from '@stsm/user/models/user';
import { UserStoreFacade } from '@stsm/user/store/user-store-facade.service';

import { CancelPremiumDialogService } from '../components/cancel-premium/cancel-premium-modal.service';
import { DesignYourTrialComponent } from '../components/design-your-trial/design-your-trial.component';
import { DiscountSubscriptionGiftDialogComponent } from '../components/discount-subscription-gift-dialog/discount-subscription-gift-dialog.component';
import { ManagePremiumComponent } from '../components/manage-premium/manage-premium.component';
import { PremiumDiscountDialogComponent } from '../components/premium-discount-dialog/premium-discount-dialog.component';
import { PREMIUM_MODAL_ID, TRIAL_PREMIUM_MODAL_ID } from '../constants/premium-modal-id';
import { HasPremiumDialogCloseEvent } from '../models/has-premium-dialog-close-event';
import { HasPremiumDialogMoneyBackGuaranteeEvent } from '../models/has-premium-dialog-money-back-guarantee-event';
import { HasPremiumDialogPurchaseEvent } from '../models/has-premium-dialog-purchase-event';
import { hasIndianCurrencyCode, PremiumPlan, PremiumPlansWithStorePricesResult } from '../models/premium-plan';
import { PREMIUM_PLANS_SERVICE } from '../tokes/premium-plans-service.token';
import { PremiumModalCall } from '../util/premium-utils';

import { PremiumMessagesService } from './premium-messages.service';
import { PremiumPlansService } from './premium-plans.service';

const PREMIUM_MODAL_OFFSET_IN_DAYS = 2;
const PREMIUM_MODAL_FREQUENCY_IN_DAYS = 7;

export abstract class PremiumModalService {
  protected currentFeature: TimeTrackFeature | undefined = 'unknown';

  protected readonly userStoreFacade: UserStoreFacade = inject(UserStoreFacade);
  protected readonly browserStorageService: BrowserStorageService = inject(BrowserStorageService);
  protected readonly loggerService: LoggerService = inject(LoggerService);
  protected readonly sessionService: SessionService = inject(SessionService);
  protected readonly dialogService: DialogService = inject(DialogService);
  protected readonly routerStoreFacade: RouterStoreFacade = inject(RouterStoreFacade);
  protected readonly platformModalService: PlatformModalService = inject(PlatformModalService);
  protected readonly analyticsService: AnalyticsBaseService = inject(ANALYTICS_SERVICE);
  protected readonly featureTimeTrackingService: FeatureTimeTrackingBaseService = inject(FEATURE_TIME_TRACKING_SERVICE);
  protected readonly translationService: TranslationService = inject(TranslationService);
  protected readonly simpleDialogService: SimpleDialogService = inject(SimpleDialogService);
  protected readonly premiumPlansService: PremiumPlansService = inject(PREMIUM_PLANS_SERVICE);
  protected readonly environment: EnvironmentBase = inject(ENVIRONMENT);
  private readonly _cancelPremiumDialogService: CancelPremiumDialogService = inject(CancelPremiumDialogService);
  private readonly _premiumMessagesService: PremiumMessagesService = inject(PremiumMessagesService);
  private readonly _onboardingStore: OnboardingStoreBase = inject(ONBOARDING_STORE);

  protected constructor() {
    this.featureTimeTrackingService.feature$.subscribe((currentFeature: TimeTrackFeature) => {
      this.currentFeature = currentFeature;
    });

    this._premiumMessagesService.onShowPremiumModal().subscribe((params: PremiumModalCall) => this.openPremiumModal(params));
  }

  init(): void {
    this.sessionService.session$.subscribe(() => {
      this._showPremiumModalOnSessionStartIfNecessary();
    });

    this.sessionService.session$
      .pipe(
        takeWhile((session: number) => session <= 3),
        takeWhile(() => !this._hasSeenPremiumDiscountGiftDialog()),
        switchMap(() => this._openDiscountedYearlySubscriptionPopupIfNecessary()),
      )
      .subscribe();
  }

  openPremiumModal(params: PremiumModalCall): void {
    this.userStoreFacade.premiumInfo$.pipe(take(1)).subscribe((premiumInfo: PremiumInfo) => {
      const shouldShowTrialDialog = !premiumInfo.usedFreeTrial && !premiumInfo.isPremium;

      if (!shouldShowTrialDialog) {
        this.managePremium(params.source);

        return;
      }

      this.analyticsService.trackEvent({
        action: `premium_modal_open`,
        properties: { ...params, feature: params.feature ?? this.currentFeature },
      });

      this._openDesignTrialDialog(params);
    });
  }

  async openDiscountPremiumGiftDialog(): Promise<void> {
    // For India, the gift dialog should not be shown
    try {
      const premiumPlan = await firstValueFrom(this.premiumPlansService.getYearlyDiscountPremiumPlan());

      if (hasIndianCurrencyCode(premiumPlan)) {
        return;
      }
    } catch (error: unknown) {
      this.loggerService.warn(error);
    }

    this.analyticsService.trackEvent({ action: 'premium_gift_popup_open' });

    const shouldOpenGift: boolean | undefined = await firstValueFrom(
      this.platformModalService.schedule<object, boolean | undefined>({
        component: DiscountSubscriptionGiftDialogComponent,
        webOptions: {
          minWidth: 360,
          maxWidth: 360,
          width: 360,
        },
        useDialogType: true,
        allowBackdropDismiss: false,
        schedulerOptions: {
          condition: () => !this._hasSeenPremiumDiscountGiftDialog(),
          onScheduled: () =>
            this.browserStorageService.setItemLocalStorage(GlobalLocalStorageKey.DID_OFFER_DISCOUNTED_YEARLY_SUBSCRIPTION, true),
        },
        disposeOnNavigation: false,
      }),
    );

    if (shouldOpenGift) {
      this.openDiscountPremiumModal({ source: 'discount_gift_popup' });
    } else {
      // only trigger if the user dismissed the popup
      this.analyticsService.trackEvent({ action: 'premium_gift_popup_close', properties: { step: 'preview' } });
    }
  }

  openDiscountPremiumModal(options: { source: PremiumModalCallSource }): void {
    this.premiumPlansService.getYearlyDiscountPremiumPlan().subscribe((premiumPlan: PremiumPlan) => {
      if (hasIndianCurrencyCode(premiumPlan)) {
        return;
      }

      this.analyticsService.trackEvent({
        action: 'premium_discount_offer_open',
        properties: { source: options.source },
      });

      const dialogRef = this.platformModalService.create({
        component: PremiumDiscountDialogComponent,
        data: <PropertiesOf<PremiumDiscountDialogComponent>>{
          premiumPlan,
        },
        cssClasses: ['premium-discount-dialog'],
        mobileOptions: {
          isAutoHeight: true,
        },
        allowBackdropDismiss: false,
      });

      this._registerCloseEvent(dialogRef, () => {
        this.analyticsService.trackEvent({
          action: 'premium_gift_popup_close',
          properties: { step: 'offer', source: options.source },
        });
      });
      this._registerOpenMoneyBackGuaranteePageEvent(dialogRef);
      this.registerPurchasePremiumPlanListener(dialogRef, options.source);
    });
  }

  managePremium(source: PremiumModalCallSource): void {
    this.premiumPlansService.getPremiumPlansWithStorePrices().subscribe(({ premiumPlans }: PremiumPlansWithStorePricesResult) => {
      this.analyticsService.trackEvent({ action: 'premium_manage_modal_open' });

      const dialogRef: DialogRef<ManagePremiumComponent> = this.platformModalService.create({
        component: ManagePremiumComponent,
        data: <PropertiesOf<ManagePremiumComponent>>{
          premiumPlans,
          paymentProvider: this.premiumPlansService.getPaymentProvider(),
        },
        mobileOptions: {
          isAutoHeight: true,
        },
        id: PREMIUM_MODAL_ID,
      });

      this._registerCloseEvent(dialogRef);
      this._registerOpenMoneyBackGuaranteePageEvent(dialogRef);
      this._registerCancelPlanEvent(dialogRef);
      this.registerPurchasePremiumPlanListener(dialogRef, source);
    });
  }

  private _openDesignTrialDialog(params: PremiumModalCall): void {
    this.premiumPlansService
      .getDesignTrialPremiumPlans()
      .subscribe(({ premiumPlans, hasPriceFetchingError }: PremiumPlansWithStorePricesResult) => {
        const dialogRef: DialogRef<DesignYourTrialComponent> = this.platformModalService.create({
          component: DesignYourTrialComponent,
          data: <InputPropertiesOf<DesignYourTrialComponent>>{
            yearlyPremiumPlans: premiumPlans,
          },
          mobileOptions: {
            isFullscreen: true,
            shouldApplySafeAreaTop: false,
          },
          webOptions: {
            maxWidth: 600,
          },
          id: TRIAL_PREMIUM_MODAL_ID,
          allowBackdropDismiss: false,
        });

        this._registerCloseEvent(dialogRef, () => this._trackPremiumModalCloseEvent(params));
        this._registerOpenMoneyBackGuaranteePageEvent(dialogRef);
        this.registerPurchasePremiumPlanListener(dialogRef, params.source);

        if (hasPriceFetchingError) {
          this.simpleDialogService.showError('PREMIUM.PLAN_PAGE.ERROR_FETCHING_PRICES');
        }
      });
  }

  private _registerCancelPlanEvent(dialogRef: DialogRef<ManagePremiumComponent>): void {
    dialogRef.componentInstance?.instance.cancelPlan
      .pipe(
        tap(() => {
          this.analyticsService.trackEvent({
            action: 'premium_manage_modal_interact',
            properties: { action: 'select_cancel_premium' },
          });
        }),
        withLatestFrom(this.userStoreFacade.premiumInfo$),
        switchMap(([premiumPlan, premiumInfo]: [PremiumPlan, PremiumInfo]) => {
          if (premiumInfo.provider !== PaymentProvider.PAYPAL) {
            this.loggerService.warn('Cancelling a subscription other than Paypal on mobile is only possible via the respective store.');

            return EMPTY;
          }

          return this._cancelPremiumDialogService.show(premiumPlan.productID).pipe(
            tap((didCancelPremium: boolean | undefined) => {
              if (didCancelPremium) {
                dialogRef.dismiss();
              }
            }),
          );
        }),
        takeUntil(dialogRef.afterClosed()),
      )
      .subscribe();
  }

  private _registerOpenMoneyBackGuaranteePageEvent(dialogRef: DialogRef<HasPremiumDialogMoneyBackGuaranteeEvent>): void {
    dialogRef.componentInstance?.instance.moneyBackGuaranteeClicked.pipe(takeUntil(dialogRef.afterClosed())).subscribe(() => {
      this.analyticsService.trackEvent({
        action: 'premium_modal_show_guarantee',
      });

      this.openMoneyBackGuaranteeLink();
    });
  }

  private _registerCloseEvent(dialogRef: DialogRef<HasPremiumDialogCloseEvent>, trackEventFn?: () => void): void {
    dialogRef.componentInstance?.instance.closeClicked.pipe(take(1), takeUntil(dialogRef.afterClosed())).subscribe(() => {
      dialogRef?.dismiss();
      trackEventFn?.();
    });
  }

  private _trackPremiumModalCloseEvent(params: PremiumModalCall): void {
    this.analyticsService.trackEvent({
      action: 'premium_modal_close',
      properties: { ...params, feature: params.feature ?? this.currentFeature },
    });
  }

  private _showPremiumModalOnSessionStartIfNecessary(): void {
    combineLatest([this.userStoreFacade.userAvailable$, this.userStoreFacade.premiumInfo$])
      .pipe(take(1))
      .subscribe(([user, premiumInfo]: [User, PremiumInfo]) => {
        if (premiumInfo.usedFreeTrial || premiumInfo.isPremium) {
          return;
        }

        if (this._shouldShowPremiumModalOnSessionStart(user)) {
          void this._openPremiumModalOnSessionStart();
        }
      });
  }

  private _openDiscountedYearlySubscriptionPopupIfNecessary(): Observable<void> {
    return this.userStoreFacade.userAvailable$.pipe(
      take(1),
      switchMap(() =>
        combineLatest([
          this.userStoreFacade.premiumInfo$,
          this._onboardingStore.isRunning$,
          this.userStoreFacade.user$.pipe(distinctUntilKeyChanged('onboardingCompleted')),
        ]),
      ),
      switchMap(([premiumInfo, isOnboardingRunning, user]: [PremiumInfo, boolean, User]) => {
        if (premiumInfo.isPremium || isOnboardingRunning || !user.onboardingCompleted) {
          return EMPTY;
        }

        if (this._hasSeenPremiumDiscountGiftDialog()) {
          return EMPTY;
        }

        return this.routerStoreFacade.url$.pipe(
          filter((url: string) => url.includes(Tab.HOME)),
          // debounce in case the home route is only visible very shortly before e.g. the forced onboarding starts
          debounceTime(500),
          take(1),
          switchMap(() => this.openDiscountPremiumGiftDialog()),
        );
      }),
    );
  }

  private _hasSeenPremiumDiscountGiftDialog(): boolean {
    if (this.environment.name === 'E2E') {
      return true;
    }

    return !isNil(this.browserStorageService.getItemLocalStorage(GlobalLocalStorageKey.DID_OFFER_DISCOUNTED_YEARLY_SUBSCRIPTION));
  }

  private async _openPremiumModalOnSessionStart(): Promise<void> {
    this.browserStorageService.setItemLocalStorage(GlobalLocalStorageKey.PREMIUM_MODAL_EXPERIMENT_LAST_SEEN, new Date().toISOString());

    // later to be used to double the frequency every time
    const counter =
      this.browserStorageService.getItemLocalStorage<number>(GlobalLocalStorageKey.PREMIUM_MODAL_ON_SESSION_START_COUNTER) ?? 0;
    this.browserStorageService.setItemLocalStorage(GlobalLocalStorageKey.PREMIUM_MODAL_ON_SESSION_START_COUNTER, counter + 1);
    this.browserStorageService.setItemLocalStorage<number>(
      GlobalLocalStorageKey.PREMIUM_MODAL_EXPERIMENT_LAST_SEEN_IN_SESSION,
      this.sessionService.getNumberOfSessions(),
    );

    await firstValueFromOrNull(
      this.dialogService.isAnyDialogOpen$.pipe(
        debounceTime(500),
        filter((isAnyDialogOpen: boolean) => !isAnyDialogOpen),
      ),
    );

    this.openPremiumModal({ source: 'session_start' });
  }

  private _shouldShowPremiumModalOnSessionStart(user: User): boolean {
    const session = this.sessionService.getNumberOfSessions();

    if (session <= 3) {
      return false;
    }

    const dateJoined = user.appUser.dateJoined;
    const lastSeen = this.browserStorageService.getItemLocalStorage<string>(GlobalLocalStorageKey.PREMIUM_MODAL_EXPERIMENT_LAST_SEEN);
    const lastSeenInSession = this.browserStorageService.getItemLocalStorage<number>(
      GlobalLocalStorageKey.PREMIUM_MODAL_EXPERIMENT_LAST_SEEN_IN_SESSION,
    );

    const daysSinceSignup = getElapsedTimeInDays(createSafeDate(dateJoined));
    const daysSinceLastSeen = isNil(lastSeen) ? Number.MAX_SAFE_INTEGER : getElapsedTimeInDays(createSafeDate(lastSeen));
    const sessionsSinceLastSeen = isNil(lastSeenInSession) ? Number.MAX_SAFE_INTEGER : Math.abs(session - lastSeenInSession);

    if (daysSinceSignup <= PREMIUM_MODAL_OFFSET_IN_DAYS) {
      return false;
    }

    if (sessionsSinceLastSeen <= 2) {
      return false;
    }

    return daysSinceLastSeen > PREMIUM_MODAL_FREQUENCY_IN_DAYS;
  }

  abstract openMoneyBackGuaranteeLink(): void;

  protected abstract registerPurchasePremiumPlanListener(
    dialogRef: DialogRef<HasPremiumDialogPurchaseEvent>,
    source: PremiumModalCallSource,
  ): void;
}
