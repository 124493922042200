import { AsyncPipe, NgIf, NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  EventEmitter,
  Inject,
  Input,
  Output,
  Signal,
  signal,
  WritableSignal,
} from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { NavController } from '@ionic/angular/standalone';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LetDirective } from '@ngrx/component';
import { isNil } from 'lodash-es';
import { combineLatest, map, Observable, ReplaySubject } from 'rxjs';

import { ANALYTICS_SERVICE, AnalyticsBaseService } from '@stsm/analytics';
import { getDateString } from '@stsm/date/functions/get-date-string';
import { FeatureToggle, FeatureToggleService } from '@stsm/devtools/services/feature-toggle.service';
import { ThemingStore } from '@stsm/global/composite/services/theming.store';
import { TranslatePipe } from '@stsm/i18n/pipes/translate.pipe';
import { LogoSource } from '@stsm/shared/models/logo-source';
import { TargetMarketProvider } from '@stsm/shared/services/target-market-provider.service';
import { IS_MOBILE_APP } from '@stsm/shared/tokens/is-mobile-app.token';
import { shareReplayRefCount } from '@stsm/shared/util/rxjs.util';
import { ButtonComponent, IconButtonComponent } from '@stsm/ui-components/button';
import { PaymentProvider } from '@stsm/user/models/payment-provider';
import { PremiumInfo } from '@stsm/user/models/premium-info';
import { UserStoreFacade } from '@stsm/user/store/user-store-facade.service';

import { HasPremiumDialogCloseEvent } from '../../models/has-premium-dialog-close-event';
import { HasPremiumDialogMoneyBackGuaranteeEvent } from '../../models/has-premium-dialog-money-back-guarantee-event';
import { HasPremiumDialogPurchaseEvent } from '../../models/has-premium-dialog-purchase-event';
import { PlanDuration, PremiumPlan } from '../../models/premium-plan';
import { mapPremiumPlansToPayOptions, PayOption } from '../pay-options-list/pay-option/pay-option';
import { PayOptionsListComponent } from '../pay-options-list/pay-options-list.component';
import { PremiumFeaturesComponent } from '../premium-features/premium-features.component';

enum ButtonMode {
  PURCHASE = 'PURCHASE',
  CANCEL = 'CANCEL',
  NOT_SUPPORTED = 'NOT_SUPPORTED',
  SWITCH_PLAN = 'SWITCH_PLAN',
}

@UntilDestroy()
@Component({
  selector: 'app-manage-premium',
  standalone: true,
  imports: [
    ButtonComponent,
    PremiumFeaturesComponent,
    PayOptionsListComponent,
    LetDirective,
    TranslatePipe,
    NgIf,
    AsyncPipe,
    NgTemplateOutlet,
    IconButtonComponent,
  ],
  templateUrl: './manage-premium.component.html',
  styleUrls: ['./manage-premium.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { ['data-cy']: 'manage-premium' },
})
export class ManagePremiumComponent
  implements HasPremiumDialogMoneyBackGuaranteeEvent, HasPremiumDialogCloseEvent, HasPremiumDialogPurchaseEvent
{
  @Input() set premiumPlans(premiumPlans: PremiumPlan[]) {
    if (premiumPlans) {
      this.premiumPlans$.next(premiumPlans);
    }
  }

  @Input() paymentProvider!: PaymentProvider;

  @Output() readonly moneyBackGuaranteeClicked: EventEmitter<void> = new EventEmitter<void>();
  @Output() readonly closeClicked: EventEmitter<void> = new EventEmitter<void>();
  @Output() readonly cancelPlan: EventEmitter<PremiumPlan> = new EventEmitter<PremiumPlan>();
  @Output() readonly purchasePlan: EventEmitter<PremiumPlan> = new EventEmitter<PremiumPlan>();

  readonly premiumLogoSource$: Observable<LogoSource> = this._themingStore.darkThemeActive$.pipe(
    map((isDarkMode: boolean) => this._targetMarketProvider.resolveLogo(isDarkMode ? 'premium-white' : 'premium')()),
  );

  protected readonly PaymentProvider: typeof PaymentProvider = PaymentProvider;
  protected readonly ButtonMode: typeof ButtonMode = ButtonMode;
  protected readonly PlanDuration: typeof PlanDuration = PlanDuration;

  protected get isCancelButtonDisabled(): boolean {
    return [PaymentProvider.GOOGLE, PaymentProvider.APPLE].includes(this.paymentProvider);
  }

  protected readonly premiumPlans$: ReplaySubject<PremiumPlan[]> = new ReplaySubject<PremiumPlan[]>(1);

  protected premiumInfo$: Observable<PremiumInfo> = this._userStoreFacade.premiumInfo$;
  protected premiumEndDate$: Observable<string> = this.premiumInfo$.pipe(
    map((premiumInfo: PremiumInfo) => (!isNil(premiumInfo.endDate) ? getDateString(premiumInfo.endDate) : '')),
  );

  protected activeSubscriptionId$: Observable<string | undefined> = this.premiumInfo$.pipe(
    map((premiumInfo: PremiumInfo) => premiumInfo.activeSubscriptionID),
  );

  protected readonly isIncorrectProvider$: Observable<boolean> = this.premiumInfo$.pipe(
    map((premiumInfo: PremiumInfo) => premiumInfo.provider !== this.paymentProvider),
  );

  protected payOptions$: Observable<PayOption[]> = this.premiumPlans$.pipe(
    map((premiumPlans: PremiumPlan[]): PayOption[] => mapPremiumPlansToPayOptions(premiumPlans)),
    shareReplayRefCount(1),
  );

  protected readonly selectedPayOption: WritableSignal<PayOption | undefined> = signal(undefined);
  protected readonly selectedOptionMonthlyPrice: Signal<string>;

  protected buttonMode$: Observable<ButtonMode> = combineLatest([toObservable(this.selectedPayOption), this.activeSubscriptionId$]).pipe(
    map(([selectedOption, activeSubscriptionId]: [PayOption | undefined, string | undefined]) => {
      if (isNil(activeSubscriptionId)) {
        return ButtonMode.PURCHASE;
      }

      if (activeSubscriptionId === selectedOption?.productID) {
        return ButtonMode.CANCEL;
      }

      return this._canSwitchPlanApple && this.paymentProvider === PaymentProvider.APPLE ? ButtonMode.SWITCH_PLAN : ButtonMode.NOT_SUPPORTED;
    }),
  );

  private readonly _canSwitchPlanApple: boolean = this._featureToggleService.getFeatureToggleValue({
    featureToggle: FeatureToggle.ENABLE_SWITCHING_PLAN_APPLE,
    valueToUseInProduction: false,
  });

  constructor(
    private readonly _targetMarketProvider: TargetMarketProvider,
    private readonly _userStoreFacade: UserStoreFacade,
    private readonly _themingStore: ThemingStore,
    private readonly _featureToggleService: FeatureToggleService,
    private readonly _navController: NavController,
    @Inject(IS_MOBILE_APP) protected readonly isMobileApp: boolean,
    @Inject(ANALYTICS_SERVICE) private readonly _analyticsService: AnalyticsBaseService,
  ) {
    combineLatest([this.premiumInfo$, this.payOptions$])
      .pipe(untilDestroyed(this))
      .subscribe(([premiumInfo, payOptions]: [PremiumInfo, PayOption[]]) => {
        const activeOption = payOptions.find((payOption: PayOption) => payOption.productID === premiumInfo.activeSubscriptionID);

        const defaultOption = payOptions.find((payOption: PayOption) => payOption.duration === PlanDuration.YEARLY);
        this.selectedPayOption.set(activeOption || defaultOption);
      });

    this.selectedOptionMonthlyPrice = computed(() => this.selectedPayOption()?.monthlyPrice ?? '');
  }

  onOptionSelected(option: PayOption): void {
    this.selectedPayOption.set(option);
  }

  onPrimaryButtonClick(mode: ButtonMode): void {
    if (mode === ButtonMode.CANCEL) {
      this.cancelPlan.emit(this.selectedPayOption());
    } else {
      this.purchasePlan.emit(this.selectedPayOption());
    }
  }

  onOpenWebappButtonClick(): void {
    this._analyticsService.trackEvent({
      action: 'premium_manage_modal_interact',
      properties: { action: 'open_web_app' },
    });

    void this._navController.navigateForward('/profile/code');
  }
}
