import { Injectable } from '@angular/core';
import { catchError, EMPTY, exhaustMap, map, merge, Observable } from 'rxjs';

import { GlobalMessageService } from '@stsm/global/composite/services/global-message.service';
import { PremiumBaseService } from '@stsm/premium/services/premium-base.service';
import { JsonObject } from '@stsm/shared/types/json-object';

@Injectable({
  providedIn: 'root',
})
export class PremiumService extends PremiumBaseService {
  constructor(private readonly _globalMessageService: GlobalMessageService) {
    super();
  }

  init(): void {
    // In case the user unlocked premium via a code, we need to refetch the premiumInfo
    merge(this.userStoreFacade.userAvailable$, this.userStoreFacade.isPupil$, this._globalMessageService.onCodeRedeemed())
      .pipe(
        exhaustMap(() =>
          this.refreshPremiumInfo().pipe(
            catchError((err: Error) => {
              console.error('Failed loading premium information: ', err);

              return EMPTY;
            }),
          ),
        ),
      )
      .subscribe();
  }

  validateSubscriptionReceipt(subscriptionId: string, orderId: string): Observable<object> {
    const body = JSON.stringify({
      subscription_id: subscriptionId,
      order_id: orderId,
    });

    return this.baseService.post(`payments/paypal/`, body);
  }

  crossgradeSubscription(newSubscriptionID: string): Observable<string> {
    const body = JSON.stringify({
      plan_id: newSubscriptionID,
    });

    return this.baseService.post('payments/paypal-revise/', body).pipe(map((res: JsonObject) => res['confirmation_link'] as string));
  }
}
