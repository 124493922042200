<button class="close-button" uiIconButton secondary uiDialogClose>
  <i class="app-icon-close"></i>
</button>

<div class="image-container">
  <img src="assets/shared/svgs/discount-present.svg" alt="" />
</div>

<div class="content-container">
  <h3 class="title display-sm-semibold">
    {{ "PREMIUM.YEARLY_DISCOUNT_POPUP.TITLE" | translate }}
  </h3>

  <button class="primary-button" uiButton size="xl" (click)="openGift()">
    {{ "PREMIUM.YEARLY_DISCOUNT_POPUP.BUTTON" | translate }}
  </button>
</div>
