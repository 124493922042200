<button uiIconButton tertiary size="lg" class="close-button" data-cy="cancel-premium-modal-close-button" (click)="keepPremium()">
  <i class="app-icon-close"></i>
</button>

<img alt="" width="250" height="60" data-cy="cancel-premium-modal-logo" [src]="premiumLogoSource()" />

<div class="content">
  <h3>{{ "PREMIUM.CANCEL.HEADER" | translate }}</h3>
  <div class="sub-header">
    {{ "PREMIUM.CANCEL.SUBHEADER" | translate }}
  </div>

  <app-premium-features isCancellationMode />
</div>

<footer>
  <button uiButton size="lg" data-cy="cancel-premium-button" (click)="cancelPremium()">
    {{ "PREMIUM.CANCEL.CONFIRM" | translate }}
  </button>

  <button uiButton secondary size="lg" data-cy="cancel-premium-keep-button" (click)="keepPremium()">
    {{ "PREMIUM.CANCEL.KEEP" | translate }}
  </button>
</footer>
