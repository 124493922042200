<div class="header-row">
  <img alt="" [src]="logoSource" />
  <button uiIconButton secondary size="sm" (click)="close()">
    <i class="app-icon-close"></i>
  </button>
</div>

<h2 class="display-md-semibold">{{ "PREMIUM.WELCOME_DIALOG.HEADING" | translate }}</h2>

<app-premium-values (moneyBackMoreClick)="onMoneyBackClicked()" />

<button slot="footer" uiButton size="lg" secondary (click)="close(true)">
  {{ "PREMIUM.WELCOME_DIALOG.WELCOME_BUTTON" | translate }}
</button>

<ui-confetti-canvas showFullscreen [autoTrigger]="true" />
