<button
  class="close-button"
  uiIconButton
  secondary
  size="lg"
  data-cy="premium-discount-dialog-close-button"
  [attr.aria-label]="'GLOBAL.CLOSE' | translate"
  (click)="closeClicked.emit()"
>
  <i class="app-icon-close"></i>
</button>

<div class="image-container">
  <img src="assets/shared/svgs/discount-present-open.svg" alt="" />
</div>

<div *ngIf="premiumPlan" class="content-container">
  <ui-pill premium>
    {{ "PREMIUM.YEARLY_DISCOUNT.LIMITED_TIME_OFFER" | translate }}
  </ui-pill>

  <h3 class="heading display-md-semibold">
    {{ "PREMIUM.YEARLY_DISCOUNT.HEADING" | translate }}
  </h3>

  <div class="price-info">
    <div>
      <span class="display-xs-semibold">
        {{ premiumPlan.monthlyPrice }}
      </span>
      <span class="text-md-regular">
        {{ "PREMIUM.YEARLY_DISCOUNT.PER_MONTH" | translate }}
      </span>
    </div>
    <div class="text-sm-regular">
      <span
        [innerHTML]="
          'PREMIUM.YEARLY_DISCOUNT.PRICE_INFO'
            | translate
              : {
                  price: premiumPlan.overallPrice,
                  normalPrice: premiumPlan.priceWithoutDiscount,
                }
        "
      >
      </span>
    </div>
  </div>

  <app-premium-features (moneyBackMoreClick)="moneyBackGuaranteeClicked.emit()" />
</div>

<div *ngIf="premiumPlan" class="button-wrapper">
  <button uiButton secondary size="lg" (click)="purchase()">
    {{ "PREMIUM.YEARLY_DISCOUNT.PURCHASE" | translate }}
  </button>
  <div class="text-sm-semibold">
    {{ "PREMIUM.YEARLY_DISCOUNT.PAYMENT_INFO" | translate: { price: premiumPlan.overallPrice } }}
  </div>
</div>
