import { Inject, Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';

import { ANALYTICS_SERVICE, AnalyticsBaseService } from '@stsm/analytics';
import { switchToVoid } from '@stsm/shared/util/rxjs.util';
import { DialogRef } from '@stsm/ui-components/dialogs/models/dialog-ref';
import { PlatformModalService } from '@stsm/ui-components/dialogs/services/platform-modal.service';

import { PremiumWelcomeDialogComponent } from './premium-welcome-dialog.component';

@Injectable({ providedIn: 'root' })
export class PremiumWelcomeDialogService {
  constructor(
    private readonly _platformModalService: PlatformModalService,
    @Inject(ANALYTICS_SERVICE) private readonly _analyticsService: AnalyticsBaseService,
  ) {}

  openPremiumWelcomeDialog(): Observable<void> {
    this._analyticsService.trackEvent({ action: 'premium_success_modal_open' });

    const dialogRef: DialogRef<PremiumWelcomeDialogComponent, boolean | undefined> = this._platformModalService.create({
      component: PremiumWelcomeDialogComponent,
      cssClasses: ['premium-welcome-dialog'],
      mobileOptions: {
        isAutoHeight: true,
      },
      webOptions: {
        maxWidth: 400,
      },
      disposeOnNavigation: false,
    });

    return dialogRef.afterClosed().pipe(
      tap((isCtaClick: boolean | undefined) => {
        this._analyticsService.trackEvent({
          action: 'premium_success_modal_interact',
          properties: {
            action: isCtaClick ? 'continue' : 'close',
          },
        });
      }),
      switchToVoid(),
    );
  }
}
