import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { TranslatePipe } from '@stsm/i18n/pipes/translate.pipe';
import { ButtonComponent, IconButtonComponent } from '@stsm/ui-components/button';
import { DialogCloseDirective } from '@stsm/ui-components/dialogs/components/dialog-close.directive';
import { DialogTemplateComponent } from '@stsm/ui-components/dialogs/components/dialog-template';
import { LoadingScreenComponent } from '@stsm/ui-components/loading-screen/loading-screen.component';
import { PillComponent } from '@stsm/ui-components/pill';

import { HasPremiumDialogCloseEvent } from '../../models/has-premium-dialog-close-event';
import { HasPremiumDialogMoneyBackGuaranteeEvent } from '../../models/has-premium-dialog-money-back-guarantee-event';
import { HasPremiumDialogPurchaseEvent } from '../../models/has-premium-dialog-purchase-event';
import { PremiumPlan } from '../../models/premium-plan';
import { PremiumFeaturesComponent } from '../premium-features/premium-features.component';

@Component({
  selector: 'app-premium-discount-dialog',
  standalone: true,
  imports: [
    NgIf,
    DialogTemplateComponent,
    ButtonComponent,
    DialogCloseDirective,
    TranslatePipe,
    PremiumFeaturesComponent,
    LoadingScreenComponent,
    PillComponent,
    IconButtonComponent,
  ],
  templateUrl: './premium-discount-dialog.component.html',
  styleUrl: './premium-discount-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PremiumDiscountDialogComponent
  implements HasPremiumDialogMoneyBackGuaranteeEvent, HasPremiumDialogCloseEvent, HasPremiumDialogPurchaseEvent
{
  @Input({ required: true }) premiumPlan: PremiumPlan | undefined;

  @Output()
  readonly purchasePlan: EventEmitter<PremiumPlan> = new EventEmitter<PremiumPlan>();

  @Output()
  readonly moneyBackGuaranteeClicked: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  readonly closeClicked: EventEmitter<void> = new EventEmitter<void>();

  protected purchase(): void {
    this.purchasePlan.emit(this.premiumPlan);
  }
}
