<div *ngIf="mode === 'dialog'" class="heading-container">
  <div class="heading">{{ "FREE_TRIAL.CHANGE_PLAN" | translate }}</div>

  <button uiIconButton tertiary size="lg" (click)="close()">
    <i class="app-icon-close"></i>
  </button>
</div>

<div class="pay-options">
  <app-pay-option
    *ngFor="let option of payOptions; trackBy: trackByPayOption"
    [payOption]="option"
    [isSelected]="isPayOptionSelected(option)"
    [isActive]="isPayOptionActive(option)"
    (click)="onPayOptionSelected(option)"
  />
</div>
