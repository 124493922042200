<button data-cy="premium-modal-close-button" uiIconButton tertiary size="lg" class="close-button" (click)="closeClicked.emit()">
  <i class="app-icon-close"></i>
</button>

<img data-cy="premium-modal-logo" class="premium-logo" alt="" width="250" height="60" [src]="premiumLogoSource$ | async" />

<ng-container *ngrxLet="premiumInfo$; let premiumInfo">
  <ng-container *ngIf="!premiumInfo.isPremium">
    <ng-container *ngTemplateOutlet="correctProviderOrNoPremium" />
  </ng-container>

  <ng-container *ngIf="(isIncorrectProvider$ | async) === true; else correctProviderOrCancelledOrNoPremium">
    @if (premiumInfo.provider === PaymentProvider.MONDIA) {
      <ng-container *ngIf="premiumEndDate$ | async as premiumEndDate">
        <div class="text-lg-semibold mondia-info">
          {{
            "PREMIUM.MONDIA_SUBSCRIPTION_INFO"
              | translate: { mondiaProvider: premiumInfo.mondiaProvider ?? "", endDateString: premiumEndDate }
          }}
        </div>
      </ng-container>
    } @else {
      <div class="incorrect-provider-wrapper">
        <div *ngIf="premiumInfo.provider === PaymentProvider.APPLE" class="incorrect-provider-disclaimer">
          {{ "PREMIUM.MANAGE_APPLE_DISCLAIMER" | translate }}
        </div>
        <div *ngIf="premiumInfo.provider === PaymentProvider.GOOGLE" class="incorrect-provider-disclaimer">
          {{ "PREMIUM.MANAGE_ANDROID_DISCLAIMER" | translate }}
        </div>
        <div *ngIf="premiumInfo.provider === PaymentProvider.PAYPAL" class="incorrect-provider-disclaimer">
          @if (premiumInfo.cancelled) {
            <ng-container *ngTemplateOutlet="cancelled" />
          } @else {
            {{ "PREMIUM.MANAGE_PAYPAL_DISCLAIMER" | translate }}
          }
        </div>
      </div>
    }

    @if (isMobileApp && premiumInfo.provider === PaymentProvider.PAYPAL && !premiumInfo.cancelled) {
      <ng-container *ngTemplateOutlet="paypalProviderOptionsOnMobile" />
    }
  </ng-container>

  <ng-template #paypalProviderOptionsOnMobile>
    <footer>
      <button uiButton size="lg" data-cy="manage-premium-open-webapp-button" (click)="onOpenWebappButtonClick()">
        {{ "PROFILE.OPEN_WEB" | translate }}
      </button>

      <button uiButton secondary size="lg" data-cy="manage-premium-cancel-button" (click)="onPrimaryButtonClick(ButtonMode.CANCEL)">
        {{ "PREMIUM.CANCEL_PLAN" | translate }}
      </button>
    </footer>
  </ng-template>

  <ng-template #correctProviderOrCancelledOrNoPremium>
    <div *ngIf="premiumInfo?.cancelled; else correctProviderOrNoPremium">
      <ng-container *ngTemplateOutlet="cancelled" />
    </div>
  </ng-template>

  <ng-template #cancelled>
    <p *ngrxLet="premiumEndDate$; let endDateString" class="cancelled-disclaimer">
      {{ "PREMIUM.CANCELLED_MESSAGE" | translate: { endDateString: endDateString } }}
    </p>
  </ng-template>

  <ng-template #correctProviderOrNoPremium>
    <app-premium-features (moneyBackMoreClick)="moneyBackGuaranteeClicked.emit()" />

    <app-pay-options-list
      mode="component"
      [payOptions]="(payOptions$ | async) ?? []"
      [selectedPayOption]="selectedPayOption()"
      [activeSubscriptionId]="(activeSubscriptionId$ | async) ?? undefined"
      (optionSelected)="onOptionSelected($event)"
    />

    <footer *ngrxLet="buttonMode$; let buttonMode">
      <div *ngIf="premiumInfo.isTrial" class="ongoing-trial">
        {{ "PREMIUM.TRIAL_ONGOING" | translate }}
      </div>

      <div *ngIf="buttonMode === ButtonMode.CANCEL && isCancelButtonDisabled" class="cancel-in-store-disclaimer">
        {{ (paymentProvider === PaymentProvider.APPLE ? "PREMIUM.CANCEL_IN_APP_STORE" : "PREMIUM.CANCEL_IN_PLAY_STORE") | translate }}
      </div>

      <div *ngIf="buttonMode === ButtonMode.PURCHASE && premiumInfo.usedFreeTrial" class="used-trial">
        {{ "PREMIUM.ALREADY_USED_TRIAL" | translate }}
      </div>

      <div
        *ngIf="buttonMode === ButtonMode.PURCHASE"
        class="selected-plan-info"
        [innerHTML]="
          (selectedPayOption()?.duration === PlanDuration.MONTHLY
            ? 'PREMIUM.SELECTED_PLAN_INFO_MONTHLY'
            : 'PREMIUM.SELECTED_PLAN_INFO_YEARLY'
          ) | translate: { price: selectedOptionMonthlyPrice() }
        "
      ></div>

      <button
        uiButton
        size="lg"
        data-cy="manage-premium-purchase-button"
        [secondary]="buttonMode === ButtonMode.CANCEL"
        [disabled]="buttonMode === ButtonMode.NOT_SUPPORTED || (buttonMode === ButtonMode.CANCEL && isCancelButtonDisabled)"
        (click)="onPrimaryButtonClick(buttonMode)"
      >
        {{
          (buttonMode === ButtonMode.CANCEL
            ? "PREMIUM.CANCEL_PLAN"
            : buttonMode === ButtonMode.NOT_SUPPORTED
              ? "PREMIUM.UNSUPPORTED"
              : buttonMode === ButtonMode.SWITCH_PLAN
                ? "PREMIUM.SWITCH_PLAN"
                : "PREMIUM.PURCHASE"
          ) | translate
        }}
      </button>
    </footer>
  </ng-template>
</ng-container>
