<i *ngIf="isSelected" class="app-icon-check check"></i>
<h4 class="title">{{ payOption.titleKey | translate }}</h4>
<p class="description">
  <span class="main-price">{{ payOption.monthlyPrice }}</span>
  <span>
    {{
      payOption.descriptionKey
        | translate: { price: payOption.monthlyPrice, yearlyPrice: payOption.yearlyPrice, overallPrice: payOption.overallPrice }
    }}
  </span>
</p>
<p *ngIf="isActive" class="badge active">{{ "PREMIUM.ACTIVE_PLAN" | translate }}</p>
<p *ngIf="!isActive && payOption.discount > 0" class="badge">{{ "FREE_TRIAL.DISCOUNT" | translate: { discount: payOption.discount } }}</p>
