import { PremiumModalCallSource } from '@stsm/analytics';

import { PremiumPlan } from '../models/premium-plan';

export abstract class PurchaseBaseService {
  abstract purchasePremiumPlan(options: {
    premiumPlan: PremiumPlan;
    source: PremiumModalCallSource;
    currentActivePlanID?: string;
  }): Promise<boolean>;
}
