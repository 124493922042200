@for (feature of features; track feature; let last = $last) {
  <div class="feature" role="listitem">
    <i [class]="!isCancellationMode ? 'app-icon-check' : 'app-icon-close'"></i>

    <p>{{ feature | translate }}</p>

    @if (!isCancellationMode && last) {
      <i
        class="app-icon-help"
        uiTooltipPosition="bottom"
        [attr.aria-label]="'FREE_TRIAL.MONEY_BACK_DESCRIPTION' | translate"
        [uiTooltip]="'FREE_TRIAL.MONEY_BACK_DESCRIPTION' | translate"
        (click)="moneyBackMoreClick.emit()"
      ></i>
    }
  </div>
}
